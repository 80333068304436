import React,{useEffect} from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import c1 from "./assets/images/c1.jpg";
import c11 from "./assets/images/c1-1.jpg";
import c12 from "./assets/images/c1-2.jpg";
import c13 from "./assets/images/c1-3.jpg";
import c14 from "./assets/images/c1-4.jpg";
import { useNavigate } from "react-router-dom";

import { ConfigProvider, Space, Image } from "antd";
import "./App.css";

function C1() {
  const navigate = useNavigate();
  const style1 = {
    padding: "10px",
    margin: "10px 0 20px",
    borderBottom: "1px solid gray",
    fontWeight: "bold",
  };
  const styleLeft = {
    width: "200px",
    margin: "20px 30px 0 70px",
    padding: "30px 0 0",
    top: "10px",
  };
  const styleImage = {
    width: "200px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    boxShadow: "3px 3px #ccc",
    borderRadius: "5px",
  };
  const styleText = {
    margin: "10px 0",
    fontSize: 20,
    fontWeight: "bolder",
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="App" style={{ width: "960px", margin: "0 auto" }}>
      <Header showMenu />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={styleLeft}>
          <Image src={c1} style={styleImage} />
          <div style={styleText}> 独石电容 </div>
        </div>
        <div style={{ flex: 1, textAlign: "left", marginRight: "30px" }}>
          <div style={style1}>外形图</div>
          <Image src={c11} style={{ width: "100%" }} />
          <div style={style1}>电性能参数</div>
          <Image src={c12} style={{ width: "100%" }} />
          <div style={style1}>编带式独石电容器外型图及编带尺寸</div>
          <Image src={c13} style={{ width: "100%" }} />
          <Image src={c14} style={{ width: "100%" }} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default C1;
