import React, { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageMain from "./assets/images/c2.jpg";
import Image1 from "./assets/images/c2-1.png";
import Image2 from "./assets/images/c2-2.png";
import Image3 from "./assets/images/c2-3.png";

import { Image } from "antd";
import "./App.css";

function C2() {
  const style1 = {
    padding: "10px",
    margin: "10px 0 10px",
    borderBottom: "1px solid gray",
    fontWeight: "bold",
  };
  const styleLeft = {
    width: "200px",
    margin: "20px 30px 0 70px",
    padding: "30px 0 0",
    top: "10px",
  };
  const styleImage = {
    width: "200px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    boxShadow: "3px 3px #ccc",
    borderRadius: "5px",
  };
  const styleText = {
    margin: "10px 0",
    fontSize: 20,
    fontWeight: "bolder",
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="App" style={{ width: "960px", margin: "0 auto" }}>
      <Header showMenu />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={styleLeft}>
          <Image src={ImageMain} style={styleImage} />
          <div style={styleText}>瓷介高压电容器</div>
        </div>
        <div style={{ flex: 1, textAlign: "left", marginRight: "30px" }}>
          <div style={style1}>外形图</div>
          <Image src={Image1} style={{ width: "100%" }} />
          <div style={style1}>温度组别</div>
          <Image src={Image2} style={{ width: "100%" }} />
          <div style={style1}>电性能参数</div>
          <Image src={Image3} style={{ width: "90%", marginLeft: "20px" }} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default C2;
