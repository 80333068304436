
import { useNavigate } from "react-router-dom";
import map from "../assets/images/map.jpg";
import { Space, Image } from "antd";

function Footer() {

  return (
    <div style={{ background: "#333", color: "white", padding: "30px 20px",marginTop:"20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "left", lineHeight: "35px" }}>
          地址：鞍山市铁西区兴盛路永宁街9号
          <br />
          电话：0412-8213754 8258928
          <br />
          传真：0412-8213754
          <br />
          邮编：114014
          <br />
          联系人：王女士
          <br />
          邮箱：asakds@aliyun.com
          <br />
          网址：www.aecdons.com
          <br />
        </div>
        <Image src={map} style={{ height: "260px", borderRadius: "20px" }} />
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        辽ICP备2023011749号-1
      </div>
    </div>
  );
}

export default Footer;
