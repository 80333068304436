import Header from "./components/Header";
import Footer from "./components/Footer";
import React, { useEffect } from "react";
import bannerImage from "./assets/images/banner.png";
import p0 from "./assets/images/p0.gif";
import c1 from "./assets/images/c1.jpg";
import c2 from "./assets/images/c2.jpg";
import c3 from "./assets/images/c3.jpg";
import c4 from "./assets/images/c4.jpg";
import c5 from "./assets/images/c5.jpg";
import c6 from "./assets/images/c6.jpg";
import z1 from "./assets/images/z1.jpg";
import z2 from "./assets/images/z2.jpg";
import z3 from "./assets/images/z3.jpg";
import z4 from "./assets/images/z4.jpg";
import z5 from "./assets/images/z5.jpg";
import z6 from "./assets/images/z6.jpg";
import { useNavigate } from "react-router-dom";

import { Space, Image } from "antd";
import "./App.css";

function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="App" style={{ width: "960px", margin: "0 auto" }}>
      <Header />
      <div style={{ paddingBottom: "5px", borderBottom: "2px solid #0527af" }}>
        <Image src={bannerImage} style={{ width: "960px" }} preview={false} />
      </div>
      <div
        style={{
          height: "30px",
          lineHeight: "30px",
          textAlign: "center",
          margin: "30px 440px",
          padding: "10px 0",
          borderBottom: "3px solid #0527af",
        }}
      >
        关于我们
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Image
          src={p0}
          style={{ width: "360px", marginRight: "20px" }}
          preview={false}
        />
        <div>
          <p
            style={{
              textIndent: "2rem",
              textAlign: "left",
              lineHeight: "30px",
              marginTop: "0",
            }}
          >
            鞍山爱可登仕电子有限公司始建于1991年的外资企业，是生产电容器系列产品的专业公司。拥有世界先进水平的电容器全自动生产线。完善的企业管理，生产出国际领先的产品，服务于社会。我公司产品有：独石电容器、片式电容器、高压电容器、安规电容器、钽质电容器、片式钽电解电容器等。产品均按国际标准（IEC）和国家标准（GB,GJB）生产，也可按用户要求生产特殊规格产品。
          </p>
          <p
            style={{
              textIndent: "2rem",
              textAlign: "left",
              lineHeight: "30px",
            }}
          >
            经过三十几年的快速发展，我公司产品遍及国内外各行业，如：航空航天、兵器工业、安防設备、通信、计算机、仪器仪表、家电等。优良的品质，周到的服务，深得用户信赖。我公司产品通过GJB9001B体系认证，获两项国家“优等品”证书，获“高新技术企业”证书，“产品出口企业”证书。
          </p>
        </div>
      </div>
      <div
        style={{
          height: "30px",
          lineHeight: "30px",
          textAlign: "center",
          margin: "30px 440px",
          padding: "10px 0",
          borderBottom: "3px solid #0527af",
        }}
      >
        主要产品
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "900px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "0 30px",
          }}
        >
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C1")}
          >
            <Image src={c1} style={{ width: "200px", marginBottom: "5px" }} />
            <div>独石电容</div>
          </div>
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C2")}
          >
            <Image src={c2} style={{ width: "200px", marginBottom: "5px" }} />
            <div>高压电容</div>
          </div>
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C3")}
          >
            <Image src={c3} style={{ width: "200px", marginBottom: "5px" }} />
            <div>交流高压电容</div>
          </div>
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C4")}
          >
            <Image src={c4} style={{ width: "200px", marginBottom: "5px" }} />
            <div>片式独石电容</div>
          </div>
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C5")}
          >
            <Image src={c5} style={{ width: "200px", marginBottom: "5px" }} />
            <div>片式钽电容</div>
          </div>
          <div
            style={{ width: "300px", textAlign: "center" }}
            onClick={() => navigate("/C6")}
          >
            <Image src={c6} style={{ width: "200px", marginBottom: "5px" }} />
            <div>钽电容</div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "30px",
          lineHeight: "30px",
          textAlign: "center",
          margin: "30px 440px",
          padding: "10px 0",
          borderBottom: "3px solid #0527af",
        }}
      >
        荣誉证书
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: "900px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            margin: "0 30px",
            overflowX: "auto",
          }}
        >
          <div style={{ marginRight: "10px", flexShrink: 0 }}>
            <Image src={z1} style={{ height: "300px", marginBottom: "5px" }} />
            <div>国军标英文证书</div>
          </div>
          <div style={{ marginRight: "10px", flexShrink: 0 }}>
            <Image src={z2} style={{ height: "300px", marginBottom: "5px" }} />
            <div>国军标中文证书</div>
          </div>
          <div style={{ marginRight: "10px", flexShrink: 0 }}>
            <Image src={z3} style={{ height: "300px", marginBottom: "5px" }} />
            <div>出口型企业证书</div>
          </div>
          <div style={{ marginRight: "10px", flexShrink: 0 }}>
            <Image src={z4} style={{ height: "300px", marginBottom: "5px" }} />
            <div>国优证书</div>
          </div>
          <div style={{ marginRight: "10px", flexShrink: 0 }}>
            <Image src={z5} style={{ height: "300px", marginBottom: "5px" }} />
            <div>国优证书</div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <Image src={z6} style={{ height: "300px", marginBottom: "5px" }} />
            <div>高新企业证书</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
