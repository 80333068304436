import React,{useEffect} from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageMain from "./assets/images/c4.jpg";
import Image1 from "./assets/images/c4-1.png";
import Image2 from "./assets/images/c4-2.png";
import Image3 from "./assets/images/c4-3.png";
import Image4 from "./assets/images/c4-4.png";
import Image5 from "./assets/images/c4-5.png";
import Image6 from "./assets/images/c4-6.png";
import Image7 from "./assets/images/c4-7.png";
import Image8 from "./assets/images/c4-8.png";
import Image9 from "./assets/images/c4-9.png";

import { Image } from "antd";
import "./App.css";

function C4() {
  const style1 = {
    padding: "10px",
    margin: "10px 0 10px",
    borderBottom: "1px solid gray",
    fontWeight: "bold",
  };
  const style2 = {
    padding: "5px",
    margin: "5px 0 5px",
  };
  const styleLeft = {
    width: "200px",
    margin: "20px 30px 0 70px",
    padding: "30px 0 0",
    top: "10px",
  };
  const styleImage = {
    width: "200px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    boxShadow: "3px 3px #ccc",
    borderRadius: "5px",
  };
  const styleText = {
    margin: "10px 0",
    fontSize: 20,
    fontWeight: "bolder",
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="App" style={{ width: "960px", margin: "0 auto" }}>
      <Header showMenu />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={styleLeft}>
          <Image src={ImageMain} style={styleImage} />
          <div style={styleText}>片式独石电容</div>
        </div>
        <div style={{ flex: 1, textAlign: "left", marginRight: "30px" }}>
          <div style={style1}>CC41 高频贴片电容容值与尺寸（NPO）</div>
          <Image src={Image1} style={{ width: "100%" }} />
          <div style={style1}>CT41 中频贴片电容容值与尺寸（X7R）</div>
          <Image src={Image2} style={{ width: "100%" }} />
          <div style={style1}>CT41 中频贴片电容容值与尺寸（X5R）</div>
          <Image src={Image3} style={{ width: "100%" }} />
          <div style={style1}>CT41 低频贴片电容容值与尺寸（Y5V）</div>
          <Image src={Image4} style={{ width: "100%" }} />
          <div style={style1}>电性能参数</div>
          <Image src={Image5} style={{ width: "100%" }} />
          <div style={style1}>编带及尺寸</div>
          <div style={style2}>4mm 纸带</div>
          <Image src={Image6} style={{ width: "100%" }} />
          <div style={style2}>2mm 纸带</div>
          <Image src={Image7} style={{ width: "100%" }} />
          <div style={style2}>塑料带</div>
          <Image src={Image8} style={{ width: "100%" }} />
          <div style={style1}>特征曲线</div>
          <Image src={Image9} style={{ width: "100%" }} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default C4;
