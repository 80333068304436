import { Routes, Route, Link } from "react-router-dom";
import C1 from "./C1";
import C2 from "./C2";
import C3 from "./C3";
import C4 from "./C4";
import C5 from "./C5";
import C6 from "./C6";
import Home from "./Home";

import { ConfigProvider, Space, Image } from "antd";
import "./App.css";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="C1" element={<C1 />} />
        <Route path="C2" element={<C2 />} />
        <Route path="C3" element={<C3 />} />
        <Route path="C4" element={<C4 />} />
        <Route path="C5" element={<C5 />} />
        <Route path="C6" element={<C6 />} />
        <Route path="Home" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
