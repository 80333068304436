import logoImage from "../assets/images/logo1.png";
import { useNavigate } from "react-router-dom";

import { Space, Image } from "antd";

function Header(props:any) {
  const { showMenu } = props;
  const navigate = useNavigate();

  return (
    <div className="App" style={{ width: "960px", margin: "0 auto" }}>
      <div
        style={{
          height: "40px",
          textAlign: "left",
          padding: "5px 0",
          borderBottom: "1px solid #0527af",
          marginBottom:"5px",
        }}
      >
        <Space size={"large"}>
          <Image src={logoImage} style={{ height: "40px" }} />
          <div style={{ fontSize: "22px" }}>鞍山爱可登仕电子有限公司</div>
        </Space>
      </div>
      {showMenu && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #0527af",
            padding: "5px 0 10px",
            marginBottom: "5px",
            fontSize: 14,
          }}
        >
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/Home")}
          >
            首页
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C1")}
          >
            独石电容
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C2")}
          >
            高压电容
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C3")}
          >
            交流高压电容
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C4")}
          >
            片式独石电容
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C5")}
          >
            片式钽电容
          </div>
          <div
            style={{ textAlign: "center", width: "100%", flex: 1,cursor:"pointer" }}
            onClick={() => navigate("/C6")}
          >
            钽电容
          </div>
        </div>
      )}

    </div>
  );
}

export default Header;
